import React from "react";
import { Container, Grid, Image } from "semantic-ui-react";
import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";
import HeadingOverImage from "../components/heading-over-image/heading-over-image";
import BodyContent from "../components/body-content/body-content";

const Podcast = ({ imgSrc, text, link }) => (
  <Grid.Column>
    <a href={link} target="_blank" rel="noopener noreferrer">
      <p>
        <Image src={imgSrc} size="mini" spaced style={{ marginRight: 15 }} />
        {text}
      </p>
    </a>
  </Grid.Column>
);

const Page = () => (
  <LayoutInner microsite>
    <SEO title="The Rocksteady Way Podcast" keywords={[`rocksteady`]} />
    <Container>
      <SecondaryNavBar
        product="generic"
        title="The Rocksteady Way Podcast"
        titlePath="/podcast/"
        titleActive={true}
        links={[]}
      />
      <HeadingOverImage
        text="MUSIC EDUCATION ​AND ITS IMPACT ​ON THE WHOLE CHILD"
        img="/images/podcast/bg.jpg"
        siteSection="primary"
      />

      <BodyContent>
        <p>
          The Rocksteady Way podcast is hosted by Rocksteady founder Mark
          Robinson and co-host Rachel Hawker, Head of Rocksteady’s teacher
          training academy.
        </p>

        <p>
          Exploring key themes in the field of music education and the positive
          impact it can have on young people’s emotional, social and musical
          development this podcast is full of inspirational stories and useful
          tips and tricks showing how a progressive music education can deliver
          long-lasting holistic benefits to children whatever their starting
          point.​
        </p>

        <br />

        <p>Listen and subscribe on your favourite platform:</p>
        <Grid stackable padded columns={2}>
          <Grid.Row>
            <Podcast
              imgSrc="/images/podcast/apple_podcasts.png"
              text="Apple Podcasts"
              link="https://podcasts.apple.com/gb/podcast/the-rocksteady-way/id1497299865"
            />
            <Podcast
              imgSrc="/images/podcast/stitcher.png"
              text="Stitcher"
              link="https://www.stitcher.com/podcast/the-rocksteady-way"
            />
          </Grid.Row>
          <Grid.Row>
            <Podcast
              imgSrc="/images/podcast/spotify.png"
              text="Spotify"
              link="https://open.spotify.com/show/1AjWr9hsbqF5IRaZP4DOvx?si=LIyjmo9WRvKJHdWyau2gyQ"
            />
            <Podcast
              imgSrc="/images/podcast/podbean.png"
              text="Podbean"
              link="https://rocksteadymusicschool.podbean.com/"
            />
          </Grid.Row>
          <Grid.Row>
            <Podcast
              imgSrc="/images/podcast/google_play.png"
              text="Google Play"
              link="https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL3JvY2tzdGVhZHltdXNpY3NjaG9vbC9mZWVkLnhtbA"
            />
          </Grid.Row>
        </Grid>
      </BodyContent>
    </Container>
  </LayoutInner>
);

export default Page;
